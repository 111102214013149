.button {
  --height: 50px;
  --min-height: var(--height);
  --horizontal-padding: 20px;
  --vertical-padding: 0;
  --color: var(--black);
  --background-color: var(--white);

  position: relative;

  display: inline-block;
  height: var(--height);
  min-height: var(--min-height);
  padding-right: var(--horizontal-padding);
  padding-left: var(--horizontal-padding);
  overflow: hidden;

  text-decoration: none;

  outline: none;
  cursor: pointer;
  user-select: none;
  transition-duration: 450ms;
  transition-property: color;
}
@media (--mobile) {
  .button {
    --height: 46px;
  }
}

.button--disabled {
  --background-color: lightgrey !important;

  cursor: default;
}

.button--theme-black.button--disabled {
  --background-color: #505050 !important;
}

.button--theme-transparent {
  --horizontal-padding: 0;
}

.button--width-full {
  display: block;
  width: 100%;
}

/** Heights **/

.button--height-small {
  --height: 44px;
}

.button--height-extra-small {
  --height: auto;
  --min-height: 32px;
  --vertical-padding: 4px;
}

/** Horizontal paddings **/

.button--horizontal-padding-large {
  --horizontal-padding: 24px;
}

/** Hover state **/

.button:not(.button--disabled):hover {
  --color: var(--secondary-800);
  --background-color: var(--secondary-400);
}

.button--theme-primary:not(.button--disabled):hover {
  --color: var(--black);
  --background-color: #fff;
}

.button--theme-black:not(.button--disabled):hover {
  --background-color: #343434;
}

.button--theme-white:not(.button--disabled):hover {
  --background-color: rgb(207, 207, 207);
}

.button--theme-transparent:not(.button--disabled):hover {
  --color: var(--black);

  --background-color: transparent;
}

/** Active state **/

.button:not(.button--disabled):active {
  --color: var(--secondary-800);
  --background-color: var(--secondary-500);
}

.button--theme-black:not(.button--disabled):active {
  --background-color: #000000;
}

.button--theme-transparent:not(.button--disabled):active {
  --color: var(--black);
  --background-color: transparent;
}

/** Background **/

.button__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: var(--background-color);
  transition-duration: 200ms;
  transition-property: opacity, background-color;
}

.button--theme-black:not(.button--disabled) {
  --background-color: var(--black);
}

.button--theme-transparent:not(.button--disabled) {
  --background-color: transparent;
}

/** Content **/

.button__content {
  display: inline-flex;
  align-items: center;
  height: var(--height);
  min-height: var(--min-height);
}
.button--width-full .button__content {
  width: 100%;
}

.button__text {
  z-index: 1;
  position: relative;

  flex: 1;
  display: flex;
  align-items: center;
  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);

  color: var(--color);
  text-decoration: none;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 125%;
  letter-spacing: 0.02em;
  text-align: center;
}
.button--theme-black .button__text {
  color: var(--white);
}
.button--theme-transparent .button__text {
  color: var(--white);
}
.button--width-full .button__text {
  justify-content: center;
}
@media (--mobile) {
  .button__text {
    font-size: 14px;
  }
}

* + .button__text {
  margin-left: 10px;
}

.button__right-icon {
  position: relative;

  display: flex;
  align-items: center;
  height: 20px;
  padding-left: 12px;
}

.button--theme-simple .button__right-icon {
  padding-left: 0;
}

* + .button__right-icon {
  margin-left: 8px;
}

.button__right-icon svg {
  --size: 16px;

  width: var(--size);
  height: var(--size);
  object-fit: contain;
}

.button--theme-black .button__right-icon svg * {
  fill: var(--white);
}

.button--theme-transparent .button__right-icon svg * {
  fill: var(--white);
}
