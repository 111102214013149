* + .image-text {
  margin-top: 100px;
}

@media (--tablet) {
  * + .image-text {
    margin-top: 50px;
  }
}

@media (--mobile) {
  * + .image-text {
    margin-top: 27px;
  }

  .slider + .image-text {
    margin-top: 0;
  }
}

@media (--mobile) {
  .image-text > .max-width {
    max-width: 100%;
    padding: 0;
  }
}

.image-text__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 65px;

  justify-content: center;
  align-items: center;
  grid-auto-flow: dense;
}

@media (--mobile) {
  .image-text__container {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.image-text__image {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

@media (--mobile) {
  .image-text__image {
    height: 327px;
    grid-row: 1;
  }
}

.image-text__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.image-text__content {
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: left;
}

@media (--mobile) {
  .image-text__content {
    padding-left: var(--page-left-padding);
    padding-right: var(--page-right-padding);
  }
}

.image-text__content__title h3 {
  max-width: 18ch;

  font-family: var(--font-family-heading);
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;

  letter-spacing: 3.2px;
}
@media (--tablet) {
  .image-text__content__title h3 {
    max-width: 27vw;
  }
}
@media (--mobile) {
  .image-text__content__title h3 {
    max-width: 15ch;
    font-size: 24px;
    letter-spacing: 0.04em;
  }
}

.image-text__content__title h3::before {
  content: '';
  position: absolute;
  top: 25px;
  right: 8%;
  bottom: 0;

  transform: translateX(35%) translateY(-31%);

  z-index: 1;

  background-image: url(/honey-spoon.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  height: 100px;
  width: 110px;
}

@media (--tablet) {
  .image-text__content__title h3::before {
    height: 130px;
  }
}

@media (max-width: 950px) {
  .image-text__content__title h3 {
    max-width: 100%;
  }

  .image-text__content__title h3::before {
    display: none;
  }
}

@media (--mobile) {
  .image-text__content__title h3::before {
    transform: translateX(43%) translateY(-64%);

    height: 106.78px;
  }
}

.image-text__content__description {
  margin-top: 24px;
}

.image-text__content__description p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: 0.02em;
}

.image-text__content__description > * + p {
  margin-top: 24px;
}
