.contact__form__title h1 {
  font-family: var(--font-family-heading);
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 125%;

  letter-spacing: 0.04em;
}

@media (--mobile) {
  .contact__form__title h1 {
    text-align: center;
  }
}

* + .contact__form__description {
  margin-top: 11px;
}

@media (--mobile) {
  * + .contact__form__description {
    margin-top: 16px;
  }
}

.contact__form__description {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;

  align-items: center;
  letter-spacing: 0.02em;
}

* + .contact__form__description p {
  margin-top: 10px;
}

.contact__form__description p {
  line-height: 1.5;
}

@media (--mobile) {
  .contact__form__description {
    text-align: center;
  }
}

.contact__form--state-success .contact__form__description {
  display: none;
}

* + #contact-form {
  margin-top: 43px;
}

.contact__form--state-success #contact-form {
  display: none;
}

.contact__form__split {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

@media (--tablet) {
  .contact__form__split {
    display: block;
  }

  .contact__form__split .contact__form__input-container:not(:first-of-type) {
    margin-top: 20px;
  }
}

.contact__form__input,
.contact__form__textarea {
  width: 100%;
  border: 0.5px solid #b8b8b8;
  padding: 10px;
}

* + .contact__form__input,
* + .contact__form__textarea {
  margin-top: 6px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
}

#contact-form > * + .contact__form__input-container,
* + .contact__form__input-container-textarea {
  margin-top: 20px;
}

.contact__form__textarea {
  resize: vertical;
  min-height: 106px;
}

.contact__form__error {
  min-height: 30px;
}

.contact__form__error p {
  font-family: var(--font-family-heading);
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.04em;

  color: rgba(0, 0, 0, 0.5);
}

* + .contact__form__error {
  margin-top: 11px;
}

* + .contact__form--state-error .contact__form__cta {
  margin-top: 11px;
}

* + .contact__form__cta {
  margin-top: 52px;
}

@media (--mobile) {
  * + .contact__form__cta {
    margin-top: 21px;
  }
}

.contact__form__success__content {
  border: 0.5px solid #b8b8b8;
  padding: 118px 80px;
  text-align: center;
}

* + .contact__form__success {
  margin-top: 11px;
}

.contact__form__success__content svg {
  height: 45px;
  width: 42px;
}

.contact__form__success__content p {
  margin-top: 35px;
  font-family: var(--font-family-heading);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;

  text-align: center;
  letter-spacing: 0.04em;

  color: #000000;
}

* + .contact__form__success__content p {
  margin-top: 35px;
}

.contact__form__success__button {
  width: 100%;
  text-align: center;
}

@media (--mobile) {
  .contact__form__success__button a {
    width: 100%;
  }
}

* + .contact__form__success__button {
  margin-top: 43px;
}
