.tracking {
  --js-scroll-offset-top: 220;

  position: relative;

  padding: 60px 0;
}

@media (--mobile) {
  .tracking {
    --js-scroll-offset-top: 170;
  }
}

@media (--mobile) {
  .tracking {
    display: flex;
    flex-direction: column-reverse;
    background: linear-gradient(
      180deg,
      #f6f4f0 66.59%,
      rgba(246, 244, 240, 0) 74.85%
    );
  }
}

* + .tracking {
  margin-top: 120px;
}
@media (--mobile) {
  * + .tracking {
    margin-top: 64px;
  }
}

.tracking__background {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

@media (--mobile) {
  .tracking__background {
    position: relative;
    height: 220px;
  }
}

.tracking__background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
}

.tracking__list {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.tracking__step {
  display: flex;
  align-items: top;
}

.tracking__title h2 {
  color: var(--black);
  font-size: 28px;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
}
@media (--mobile) {
  .tracking__title h2 {
    max-width: 17ch;
    margin-left: auto;
    margin-right: auto;

    text-align: center;
    font-size: 22px;
    line-height: 1.3;
    letter-spacing: 0.04em;
  }
}

.tracking__step__number__value {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 100%;
  font-family: var(--font-family-heading);
  background-color: #776c57;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  padding: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tracking__step__content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 24px;
  align-items: top;
}

.tracking__step__title {
  color: #776c57;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}
@media (--mobile) {
  .tracking__step__title {
    font-size: 16px;
  }
}

.tracking__step__description {
  padding-left: 6px;
  color: #776c57;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;

  border-left: 2px solid #bbb6ab;
}
.tracking__step__description a {
  color: #776c57;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 89%;
  text-decoration-line: underline;
}

@media (--mobile) {
  .tracking__list {
    padding-bottom: 20px;
  }
}
