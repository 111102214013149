.title {
  position: relative;
  z-index: 1;
  margin-top: 70px;
}
@media (--mobile) {
  .title {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.hero + .subject .title {
  margin-top: 64px;
}
@media (--mobile) {
  .hero + .subject .title {
    margin-top: 40px;
  }
}

* + .title {
  margin-top: 88px;
}
@media (--tablet) {
  * + .title {
    margin-top: 50px;
  }
}
@media (--mobile) {
  * + .title {
    margin-top: 27px;
  }
}

.title--first {
  margin-top: 160px;
}
@media (--mobile) {
  .title--first {
    margin-top: 120px;
  }
}

.title__container {
  text-align: center;
  color: var(--black);
}

.title__title h1,
.title__title h2 {
  font-size: 32px;
  font-family: var(--font-family-heading);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 125%;
}
@media (--mobile) {
  .title__title h1,
  .title__title h2 {
    font-size: 24px;
    letter-spacing: 0.96px;
  }
}
@media (--tablet) {
  .title__title {
    font-size: 32px;
  }
}

* + .title__title {
  margin-top: 12px;
}

* + .title__description {
  margin-top: 16px;
}

.title__description p {
  max-width: 599px;
  margin-left: auto;
  margin-right: auto;

  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 0.32px;
}

.title__description * + p {
  margin-top: 8px;
}

.title__description a {
  color: var(--black);
  text-decoration: none;
}
.title__description a:hover {
  text-decoration: underline;
}
