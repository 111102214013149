@font-face {
  font-display: swap;
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: url('/font-faces/playfair-display-v36-latin-regular.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Playfair Display';
  font-style: italic;
  font-weight: 400;
  src: url('/font-faces/playfair-display-v36-latin-italic.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 500;
  src: url('/font-faces/playfair-display-v36-latin-500.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Playfair Display';
  font-style: italic;
  font-weight: 500;
  src: url('/font-faces/playfair-display-v36-latin-500italic.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  src: url('/font-faces/playfair-display-v36-latin-600.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Playfair Display';
  font-style: italic;
  font-weight: 600;
  src: url('/font-faces/playfair-display-v36-latin-600italic.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  src: url('/font-faces/playfair-display-v36-latin-700.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Playfair Display';
  font-style: italic;
  font-weight: 700;
  src: url('/font-faces/playfair-display-v36-latin-700italic.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 800;
  src: url('/font-faces/playfair-display-v36-latin-800.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Playfair Display';
  font-style: italic;
  font-weight: 800;
  src: url('/font-faces/playfair-display-v36-latin-800italic.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 900;
  src: url('/font-faces/playfair-display-v36-latin-900.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Playfair Display';
  font-style: italic;
  font-weight: 900;
  src: url('/font-faces/playfair-display-v36-latin-900italic.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('/font-faces/poppins-v20-latin-100.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: url('/font-faces/poppins-v20-latin-100italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('/font-faces/poppins-v20-latin-200.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: url('/font-faces/poppins-v20-latin-200italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('/font-faces/poppins-v20-latin-300.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('/font-faces/poppins-v20-latin-300italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('/font-faces/poppins-v20-latin-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url('/font-faces/poppins-v20-latin-italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('/font-faces/poppins-v20-latin-500.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('/font-faces/poppins-v20-latin-500italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('/font-faces/poppins-v20-latin-600.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('/font-faces/poppins-v20-latin-600italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('/font-faces/poppins-v20-latin-700.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('/font-faces/poppins-v20-latin-700italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('/font-faces/poppins-v20-latin-800.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url('/font-faces/poppins-v20-latin-800italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('/font-faces/poppins-v20-latin-900.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url('/font-faces/poppins-v20-latin-900italic.woff2') format('woff2');
}
