* + .list-products {
  margin-top: 62px;
}

.list-products__content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}
@media (--mobile) {
  .list-products__content {
    display: block;
  }

  * + .list-products__content__details-card,
  * + .list-products-item {
    margin-top: 24px;
  }
}

.list-products-item {
  display: flex;
  flex-direction: column;
}

.list-products-item__image {
  position: relative;

  height: 227px;
}

.list-products-item__top {
  flex: 1;
}

* + .list-products-item__top {
  margin-top: 16px;
}

.list-products__content h3 {
  color: rgba(0, 0, 0, 0.7);
  font-family: var(--font-family);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.list-products__content * + p {
  margin-top: 10px;
}
.list-products__content p {
  font-family: var(--font-family);
  color: #776c57;
}

.list-products-item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.list-products-item * + a {
  margin-top: 16px;
}

.list-products-item a {
  display: block;
  width: 100%;
  padding: 16px;

  color: var(--white);
  text-decoration: none;
  text-align: center;
  background-color: var(--primary-500);
  transition: 200ms background-color;
}
.list-products-item a:hover {
  background-color: var(--primary-400);
}
.list-products-item a:active {
  background-color: var(--primary-500);
}

.list-products__content__details-card {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 40px 24px;
  background-color: var(--primary-500);
  min-height: 261px;

  @media (--mobile) {
    min-height: 320px;
  }
}

.list-products__content__details-card a {
  text-decoration: none;
}

.list-products__content__details-card h3 {
  color: #fff;
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.36px;
}

.list-products__content__details-card * + svg {
  margin-top: 24px;
}

.list-products__content__details-card a:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.list-products__content__details-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
