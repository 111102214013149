* + .contact {
  margin-top: 200px;
  margin-bottom: 100px;
}

.contact__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 42px;
}

@media (--tablet) {
  .contact__container {
    gap: 25px;
  }

  * + .contact {
    margin-top: 150px;
    margin-bottom: 50px;
  }
}

@media (--mobile) {
  .contact__container {
    grid-template-columns: 1fr;
  }

  .contact__informations {
    order: 1;
  }

  * + .contact {
    margin-top: 130px;
  }
}

.contact__informations__image {
  position: relative;
  width: 100%;
  height: 340px;
}

@media (--mobile) {
  .contact__informations__image {
    max-height: 238px;
  }
}

.contact__informations__image img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact__informations__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #f2f2f0;
  padding: 32px;
}

@media (--tablet) {
  .contact__informations__content {
    display: block;
  }

  * + .contact__informations__content__section {
    margin-top: 20px;
  }
}

.contact__informations__content__section h3 {
  font-family: var(--font-family-heading);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;

  letter-spacing: 0.06em;

  color: #000000;
}

.contact__informations__content__section p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  /* or 18px */

  letter-spacing: 0.08em;

  color: #000000;
}

.contact__informations__content__section p {
  margin-top: 8px;
}
