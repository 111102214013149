html {
  background-color: var(--primary-500);
}

body {
  background-color: var(--white);
}

html,
body {
  font-family: var(--font-family);
  color: var(--black);
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.3);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  font-family: inherit;
  line-height: inherit;
}

img {
  display: inline-block;
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
