:root {
  --cc-bg: var(--white);
  --cc-text: var(--black);
  --cc-btn-primary-bg: var(--primary-500);
  --cc-btn-primary-hover-bg: rgb(63, 61, 61);
  --cc-btn-secondary-bg: #f6f4f0;
  --cc-btn-secondary-hover-bg: #dad8d5;
}

body .c-bn {
  border-radius: 0 !important;
}

body #cm,
body #cm.right {
  max-width: 28em;
}
@media (--mobile) {
  body #cm,
  body #cm.right {
    --offset: 16px;

    left: calc(var(--offset) + env(safe-area-inset-left));
    right: calc(var(--offset) + env(safe-area-inset-right));
    bottom: calc(var(--offset) + env(safe-area-inset-bottom));

    padding: 16px !important;
  }
}

body .cc_div .c-bn {
  padding: 1em 1em;
}

@media (--mobile) {
  body #c-ttl {
    margin-bottom: 6px;
  }
}

@media (--mobile) {
  body #c-txt  {
    font-size: 14px;
  }
}

@media (--mobile) {
  body .cc_div #c-bns {
    margin-top: 12px;
  }
}

@media (--mobile) {
  body #c-bns button:first-child,
  body #s-bns button:first-child {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  body #cm .c-bn,
  .cc_div .c-bn {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
