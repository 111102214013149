.edit-quantity {
  display: grid;
  width: 100px;
  grid-template-columns: 0.9fr 1fr 0.9fr;
  text-align: center;

  font-family: var(--font-family-heading);
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.36px;

  border-radius: 5px;
  border: 1px solid rgba(var(--black-rgb), 0.1);
  user-select: none;
}

.edit-quantity__text {
  position: relative;
}

.edit-quantity__text input {
  width: 100%;
  text-align: center;
}

.edit-quantity__text input[type='number']::-webkit-inner-spin-button,
.edit-quantity__text input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.edit-quantity button {
  position: relative;
  color: var(--black);
  transition: background-color 200ms;
}
.edit-quantity button:hover {
  background-color: rgba(var(--black-rgb), 0.02);
}
.edit-quantity button:active {
  background-color: rgba(var(--black-rgb), 0.05);
}

.edit-quantity button:first-child::after,
.edit-quantity button:last-child::before {
  --transition-duration: 400ms;

  position: absolute;
  top: 0;

  content: '';
  width: 0;
  height: 100%;
  border-left: 1px solid rgba(var(--black-rgb), 0.2);

  transform-origin: center;
  transform: scaleY(0.5);
  transition: transform var(--transition-duration);
}
.edit-quantity button:first-child::after {
  right: 0;
}
.edit-quantity button:last-child::before {
  left: 0;
}
.edit-quantity button:hover:first-child::after,
.edit-quantity button:hover:last-child::before {
  transform: scaleY(1);
}
.edit-quantity button:active:first-child::after,
.edit-quantity button:active:last-child::before {
  --transition-duration: 100ms;

  transform: scaleY(0.8);
}

.edit-quantity--loading {
  opacity: 0.5;
  pointer-events: none;
}
